.service-card {
    width: 239px;

    @include above($lg) {
        width: 344px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 22px 18px 16px 18px;
        height: calc(100% - 222px);
        border: 1px solid rgba(49, 71, 98, 0.1);
        border-top-color: $color-white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        @include above($lg) {
            padding: 21px 42px 15px 42px;
        }
    }

    &__header {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100% - 2px);
            height: 10px;
            background-color: $color-white;
            border-right: 1px solid rgba(49, 71, 98, 0.1);
            border-left: 1px solid rgba(49, 71, 98, 0.1);
        }
    }

    &__img-wrap {
        position: relative;
        z-index: 1;
    }

    &__img {
        display: block;
        width: 100%;
        height: 222px;
        object-fit: cover;
        -webkit-clip-path: url('#service-bg-sm');
        clip-path: url('#service-bg-sm');

        @include above($lg) {
            -webkit-clip-path: url('#service-bg-lg');
            clip-path: url('#service-bg-lg');
        }
    }

    &__title {
        margin-bottom: 16px;

        @include above($lg) {
            margin-bottom: 16px;
        }
    }

    &__description {
        font-size: 12px;
        line-height: 1.33;
        opacity: 0.6;
        margin-bottom: 12px;

        @include above($lg) {
            margin-bottom: 34px;
            font-size: 16px;
            line-height: 1.5;
        }
    }

    &__footer {
        margin-top: 12px;

        @include above($lg) {
            margin-top: 34px;
        }
    }
}
