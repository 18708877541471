.horizontal-slider {
    position: relative;

    &__list:not(.slick-initialized) {
        display: flex;
        overflow: auto;
        margin-left: -4px;

        @include above($lg) {
            margin-left: -8px;
        }
    }

    &__slide {
        margin-left: 4px;
        margin-right: 4px;

        @include above($lg) {
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    &__slide,
    &__list.slick-initialized &__slide {
        display: inline-flex;
    }

    &__btn {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background-color: $color-special-white;
        box-shadow: 0 2px 48px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &--prev {
            left: -29px;
        }

        &--next {
            right: -29px;
        }

        .horizontal-slider__list:not(.slick-initialized) ~ & {
            display: none;
        }

        @include below($lg) {
            display: none;
        }
    }

    &__btn-icon {
        width: 16px;
        height: 16px;
        fill: $color-cinnabar;
    }
}
