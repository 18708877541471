.services {
    padding-top: 34px;
    padding-bottom: 17px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 76px;
        left: 0;
        background-color: $color-selago;
        border-radius: 20px;
        width: 100%;
        height: 120px;
        pointer-events: none;
        z-index: -1;

        @include above($lg) {
            left: 32px;
            top: 113px;
            height: 234px;
        }
    }

    &__container {
        position: relative;

        @include above($lg) {
            &::before {
                content: '';
                position: absolute;
                top: 173px;
                left: -18px;
                width: 95px;
                height: 72px;
                z-index: 1;
                @include dots-gradient(rgba($color-blue-degree, 0.6));
            }
        }
    }

    &__title {
        margin-bottom: 18px;

        @include above($lg) {
            margin-bottom: 33px;
        }
    }

    &__slider-wrap {
        z-index: 2;
    }
}
