@include font-face(
    'Montserrat',
    '../../assets/fonts/Montserrat/Montserrat-Medium',
    swap,
    $font--medium,
    $exts: woff2 woff
);
@include font-face(
    'Montserrat',
    '../../assets/fonts/Montserrat/Montserrat-SemiBold',
    swap,
    $font--semi-bold,
    $exts: woff2 woff
);
@include font-face('Montserrat', '../../assets/fonts/Montserrat/Montserrat-Bold', swap, $font--bold, $exts: woff2 woff);
