.intro {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: -270px;
        left: 0;
        background: url('../../assets/images/intro/intro-bg.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 1580px;
        pointer-events: none;
        z-index: -1;

        @include above($lg) {
            min-height: 1477px;
            top: 80px;
            left: 200px;
        }
    }

    &__container {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: -25px;
            right: 0;
            width: 72px;
            height: 95px;

            @include dots-gradient(rgba($color-blue-degree, 0.6));

            @include above($lg) {
                bottom: -50px;
            }
        }
    }

    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 464px;
        padding-top: 64px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border: 1px solid transparent; // fix a visual bug in Google Chrome displaying non-set border
        border-radius: 30px;
        z-index: 2;

        @include above($lg) {
            flex-direction: row;
            align-items: flex-start;
            height: 576px;
            padding: 42px 72px 58px 74px;
        }
    }

    &__info {
        @include above($lg) {
            width: 73%;
            margin-top: 57px;
        }
    }

    &__title {
        text-transform: uppercase;
        color: $color-white;
        text-align: center;

        @include below($lg) {
            padding-right: 8px;
            padding-left: 8px;
        }

        @include above($lg) {
            text-align: left;
        }
    }

    &__further {
        margin-top: 70px;

        @include below($lg) {
            display: none;
        }
    }

    &__further-btn {
        display: block;
        width: 24px;
        height: 64px;
        animation-name: further-scroll;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
        animation-iteration-count: infinite;

        &:focus-visible {
            outline: thin dotted black;
        }

        @keyframes further-scroll {
            0% {
                opacity: 0;
            }
            10% {
                transform: translateY(0);
                opacity: 1;
            }
            100% {
                transform: translateY(15px);
                opacity: 0;
            }
        }
    }

    &__features {
        @include between($md, $lg) {
            position: relative;
            left: -25px;
        }
    }

    &__features-item {
        filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
        & + & {
            margin-top: 7px;

            @include above($lg) {
                margin-top: 26px;
            }
        }
    }

    &__features-card {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 288px;
        padding: 24px;
        border-radius: 20px;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%22320%22%20height%3D%22176%22%20viewBox%3D%220%200%20320%20176%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cg%20filter%3D%22url%28%23filter0_d%29%22%3E%0A%3Cpath%20d%3D%22M16%20106V66.9361C16%2055.5316%2025.5216%2046.4357%2036.9142%2046.957L284.914%2058.3055C295.593%2058.7942%20304%2067.5944%20304%2078.2846V106C304%20117.046%20295.046%20126%20284%20126H36C24.9543%20126%2016%20117.046%2016%20106Z%22%20fill%3D%22white%22/%3E%0A%3C/g%3E%0A%3Cdefs%3E%0A%3Cfilter%20id%3D%22filter0_d%22%20x%3D%22-32%22%20y%3D%220.935669%22%20width%3D%22384%22%20height%3D%22175.064%22%20filterUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%0A%3CfeFlood%20flood-opacity%3D%220%22%20result%3D%22BackgroundImageFix%22/%3E%0A%3CfeColorMatrix%20in%3D%22SourceAlpha%22%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%20127%200%22/%3E%0A%3CfeOffset%20dy%3D%222%22/%3E%0A%3CfeGaussianBlur%20stdDeviation%3D%2224%22/%3E%0A%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.15%200%22/%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in2%3D%22BackgroundImageFix%22%20result%3D%22effect1_dropShadow%22/%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in%3D%22SourceGraphic%22%20in2%3D%22effect1_dropShadow%22%20result%3D%22shape%22/%3E%0A%3C/filter%3E%0A%3C/defs%3E%0A%3C/svg%3E%0A');
        background-repeat: no-repeat;
        background-position: center center;

        @include above($lg) {
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%22368%22%20height%3D%22231%22%20viewBox%3D%220%200%20368%20231%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cg%20filter%3D%22url%28%23filter0_d%29%22%3E%0A%3Cpath%20d%3D%22M48%20161V66.7151C48%2055.0219%2057.9881%2045.8227%2069.6418%2046.7826L301.642%2065.8919C312.016%2066.7464%20320%2075.4153%20320%2085.8244V161C320%20172.046%20311.046%20181%20300%20181H68C56.9543%20181%2048%20172.046%2048%20161Z%22%20fill%3D%22white%22/%3E%0A%3C/g%3E%0A%3Cdefs%3E%0A%3Cfilter%20id%3D%22filter0_d%22%20x%3D%220%22%20y%3D%220.713745%22%20width%3D%22368%22%20height%3D%22230.286%22%20filterUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22sRGB%22%3E%0A%3CfeFlood%20flood-opacity%3D%220%22%20result%3D%22BackgroundImageFix%22/%3E%0A%3CfeColorMatrix%20in%3D%22SourceAlpha%22%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%20127%200%22/%3E%0A%3CfeOffset%20dy%3D%222%22/%3E%0A%3CfeGaussianBlur%20stdDeviation%3D%2224%22/%3E%0A%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.15%200%22/%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in2%3D%22BackgroundImageFix%22%20result%3D%22effect1_dropShadow%22/%3E%0A%3CfeBlend%20mode%3D%22normal%22%20in%3D%22SourceGraphic%22%20in2%3D%22effect1_dropShadow%22%20result%3D%22shape%22/%3E%0A%3C/filter%3E%0A%3C/defs%3E%0A%3C/svg%3E%0A');
            background-repeat: no-repeat;
        }

        @include above($lg) {
            width: 272px;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &__features-card-img-wrap {
        position: relative;
        width: 32px;
        height: 32px;

        @include above($lg) {
            width: 56px;
            height: 56px;
        }
    }

    &__features-card-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .intro__features-item:first-child & {
            @include below($lg) {
                width: 26px;
                height: 32px;
            }
        }

        .intro__features-item:nth-child(2) & {
            @include below($lg) {
                width: 26px;
                height: 32px;
            }
        }

        .intro__features-item:last-child & {
            @include below($lg) {
                width: 32px;
                height: 22px;
            }
        }
    }

    &__features-card-description {
        margin-left: 24px;
        width: 80%;
        font-size: 12px;
        line-height: 16px;
        font-weight: $font--semi-bold;

        @include above($lg) {
            font-size: 14px;
            line-height: 1.7;
            margin-left: 16px;
        }
    }
}
