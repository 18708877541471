.email {
    font-weight: $font--semi-bold;

    &__link {
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 22px;
        height: 18px;
        fill: $color-cinnabar;
    }

    &__text {
        margin-left: 11px;
    }
}
