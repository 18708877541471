.portfolio-full-slider {
    list-style: none;
    margin: 0;
    margin-bottom: 16px;
    padding: 0;

    @include above($extra-md) {
        flex: none;
        width: 687px;
        margin-bottom: 0;
        margin-right: 17px;
    }

    &__img-wrap {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 61.111111%; // height(176)/width(288)*100
        max-width: 687px;
        border-radius: 20px;

        @include above($extra-md) {
            width: 687px;
            height: 432px;
            padding-top: 0;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
