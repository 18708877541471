@charset "UTF-8";

// Setup
@import '../setup/setup';
@import '../blocks/typography';
@import '../blocks/general';
@import '../blocks/reset';
@import '../blocks/root';
@import '../blocks/page';
@import '../blocks/page-container';
@import '../blocks/object-fit-cover';
@import '../blocks/clip-path-images';
@import '../blocks/btn/btn';
@import '../blocks/btn/solid-btn';
@import '../blocks/btn/ghost-btn';
@import '../blocks/title';
@import '../blocks/d-none';
@import '../blocks/hidden';
@import '../blocks/unordered-list';
@import '../blocks/visuallyhidden';
@import '../blocks/link';
@import '../blocks/horizontal-slider';
@import '../blocks/slider-card';
@import '../blocks/header';
@import '../blocks/phone';
@import '../blocks/email';
@import '../blocks/mobile-menu-overlay';
@import '../blocks/mobile-menu';
@import '../blocks/footer';
@import '../blocks/intro';
@import '../blocks/about';
@import '../blocks/features-card';
@import '../blocks/contacts';
@import '../blocks/services';
@import '../blocks/service-card';
@import '../blocks/recommendation-letters';
@import '../blocks/licenses';
@import '../blocks/portfolio';
@import '../blocks/portfolio-card';
@import '../blocks/universal-modal';
@import '../blocks/service-detailed';
@import '../blocks/portfolio-modal';
@import '../blocks/portfolio-full-slider';
@import '../blocks/portfolio-thumb-slider';
@import '../blocks/focus-visible';
