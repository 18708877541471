.page {
    color: $color--text;
    display: flex;
    flex-direction: column;
    font-family: $font-stack--base;
    font-size: $font-size--page;
    font-weight: $font--normal;
    height: 100%;
    line-height: $lh--base;
    margin: 0;
    min-width: $width--min;
    padding: 0;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;

    &__main {
        flex: 1 0 auto;
        overflow: hidden;
    }
}
