.title {
    color: $color--text;
    font-family: $font-stack--base;
    font-weight: $font--semi-bold;

    &--h1,
    &--h3 {
        font-size: 22px;
        line-height: 1.18;

        @include above($lg) {
            font-size: 48px;
            line-height: 1.2;
        }
    }

    &--h2 {
        font-size: 22px;
        font-weight: $font--bold;
        line-height: 1.18;

        @include above($lg) {
            font-size: 56px;
            line-height: 1.28;
        }
    }

    &--h4 {
        font-size: 18px;
        line-height: 1.33;

        @include above($lg) {
            font-size: 24px;
        }
    }

    &--h5 {
        font-size: 18px;
        line-height: 1.33;

        @include above($lg) {
            font-size: 20px;
            line-height: 1.2;
        }
    }
}
