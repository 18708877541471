.header {
    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 13px;
        padding-bottom: 14px;

        @include above($lg) {
            justify-content: flex-start;
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }

    &__logo-wrap {
        width: 32px;
        height: 39px;

        @include above($lg) {
            width: 38px;
            height: 45px;
            margin-right: 102px;
        }
    }

    &__logo {
        display: block;
        width: 100%;
        height: auto;
    }

    &__menu-navigation {
        display: none;

        @include above($lg) {
            display: block;
            color: inherit;
        }
    }

    &__menu-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -48px;
    }

    &__menu-item {
        margin-left: 42px;
    }

    &__menu-link {
        display: block;
        padding-top: 13px;
        padding-bottom: 13px;
        font-weight: $font--semi-bold;
        font-size: 14px;
        color: inherit;

        &:focus-visible {
            outline: thin dotted black;
        }
    }

    &__menu {
        @include above($lg) {
            display: none;
        }
    }

    &__menu-btn {
        display: block;
        padding: 8px;
    }

    &__menu-line {
        display: block;
        width: 22px;
        height: 2px;
        background-color: $color-cinnabar;
        transition: 0.2s;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;

        & + & {
            margin-top: 4px;
        }

        .header__menu-btn.is-active &:first-child {
            transform: translateY(5px) rotate(-48deg);
            width: 24px;
        }

        .header__menu-btn.is-active &:nth-child(2) {
            opacity: 0;
        }

        .header__menu-btn.is-active &:last-child {
            transform: translateY(-7px) rotate(48deg);
            width: 24px;
        }
    }

    &__phone {
        margin-left: auto;
        @include below($lg) {
            display: none;
        }
    }
}
