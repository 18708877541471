.phone {
    font-weight: $font--semi-bold;

    &__link {
        display: flex;
        align-items: center;

        &:focus-visible {
            outline: thin dotted black;
        }

        .mobile-menu__phone & {
            justify-content: center;
        }
    }

    &__icon {
        width: 21px;
        height: 20px;
        fill: $color-cinnabar;
    }

    &__text {
        margin-left: 11px;
    }
}
