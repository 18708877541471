* {
    box-sizing: inherit;
}

html {
    &.ov-hidden {
        overflow: hidden;
        background-color: $color-violet-blue;
    }
}

body {
    position: relative;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    &.ov-hidden {
        overflow: hidden;
        background-color: $color-white;
    }

    & + div {
        display: none !important;
    }
}
