.mobile-menu-overlay {
    position: absolute;
    width: 100%;
    left: 0;
    top: 65px;
    background-color: rgba($color-violet-blue, 0.4);
    z-index: 2;
    height: 0;
    overflow: hidden;
    opacity: 0;
    will-change: opacity;
    transition-property: height, opacity;
    transition-duration: 0ms, 750ms;
    transition-delay: 550ms, 0ms;

    &.is-active {
        height: calc(100vh - 66px);
        opacity: 1;
        transition-property: height, opacity;
        transition-delay: 0ms, 0ms;
    }

    @include above($lg) {
        display: none;
    }
}
