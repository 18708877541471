.root {
    font-family: 'Montserrat', sans-serif;
    font-size: $fs16;
    font-weight: $font--medium;
    background: $color-bg--page;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
    -webkit-overflow-scrolling: touch;
}
