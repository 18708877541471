.recommendation-letters {
    margin-bottom: 64px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: -270px;
        left: 0;
        background: url('../../assets/images/recommendation-letters-bg.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 689px;
        pointer-events: none;
        z-index: -1;

        @include above($lg) {
            min-height: 1169px;
            top: 100px;
        }
    }

    @include above($lg) {
        margin-bottom: 128px;
    }

    &__title {
        margin-bottom: 28px;
    }
}
