.unordered-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        position: relative;
        padding-left: 14px;
        font-size: inherit;
        line-height: inherit;

        & + & {
            margin-top: 5px;

            @include above($lg) {
                margin-right: 14px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 9px;
            width: 6px;
            height: 6px;
            background-color: $color-cinnabar;
            border-radius: 50%;
        }
    }
}
