.page-container {
    margin: 0 auto;
    width: calc(100% - 32px);

    @include above($md) {
        width: 89.0625%;
    }

    @include above($lg) {
        width: 1136px;
    }
}
