.ghost-btn {
    width: 100%;
    padding: 11px 20px 9px 20px;
    border: 2px solid $color-gainsborough;
    font-size: 14px;
    font-weight: $font--semi-bold;
    line-height: 1.21;
    color: inherit;
    background-color: transparent;
    transition-property: border-color, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    white-space: nowrap;
    border-radius: 20px;

    &--cinnabar {
        color: $color-cinnabar;

        &:hover,
        &:active {
            border-color: $color-cinnabar;
        }
    }
}
