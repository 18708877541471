.contacts {
    @include above($lg) {
        margin-bottom: 232px;
    }

    &__container.page-container {
        position: relative;

        @include above($lg) {
            &::before {
                content: '';
                position: absolute;
                bottom: calc(100% + 16px);
                right: 144px;
                width: 95px;
                height: 72px;

                @include dots-gradient(rgba($color-blue-degree, 0.6));
            }
        }
    }

    &__inner {
        background-color: $color-white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 0 2px 48px rgba(0, 0, 0, 0.15);
        padding-top: 38px;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 310px;
        border: 1px solid rgba(49, 71, 98, 0.1);

        @include above($lg) {
            padding-top: 63px;
            padding-left: 58px;
            padding-right: 58px;
            padding-bottom: 62px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            width: 1064px;
        }
    }

    &__inner-content {
        @include between($md, $lg) {
            position: relative;
            left: -3px;
        }
    }

    &__title {
        line-height: 1;
        margin-bottom: 12px;

        @include above($lg) {
            margin-bottom: 16px;
        }
    }

    &__address {
        font-size: $font-size--text;
        line-height: $lh--base;
        margin-bottom: 23px;

        @include above($lg) {
            margin-bottom: 31px;
        }
    }

    &__link-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__link-item + &__link-item {
        margin-top: 18px;
    }

    &__map-wrapper {
        background-color: #e9e9e9;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        filter: drop-shadow(0 2px 48px rgba(0, 0, 0, 0.15));
        height: 277px;
        width: 100%;
        position: absolute;
        bottom: 0;
        overflow: hidden;

        @include above($lg) {
            width: 776px;
            height: 400px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            right: 0;
            top: 56px;
            bottom: auto;
        }
    }

    &__map-iframe {
        width: 100%;
        height: 277px;
        filter: grayscale(1);

        @include above($lg) {
            height: 400px;
        }
    }
}
