.portfolio-modal {
    &__header {
        margin-bottom: 20px;

        @include above($extra-md) {
            margin-bottom: 10px;
        }
    }

    &__name {
        margin-bottom: 5px;
    }

    &__date {
        display: block;
        color: rgba($color-gentian-blue, 0.6);
        font-size: 12px;
        font-weight: $font--medium;
        line-height: 1;

        @include above($lg) {
            font-size: 16px;
        }
    }

    &__body {
        @include above($extra-md) {
            display: flex;
        }
    }
}
