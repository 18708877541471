.licenses {
    margin-bottom: 68px;

    @include above($lg) {
        margin-bottom: 74px;
    }

    &__title {
        margin-bottom: 28px;
    }
}
