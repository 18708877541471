.slider-card {
    border-radius: 20px;
    background-color: $color-white;
    border: 1px solid rgba(49, 71, 98, 0.1);
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 36px;
    width: 239px;

    @include above($lg) {
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        width: 344px;
    }

    &__header {
        border-radius: 20px;
        background-color: $color-selago;
        padding: 16px 32px;
        margin-bottom: 22px;
    }

    &__image-wrap {
        display: block;
        width: 143px;
        height: 210px;
        overflow: hidden;

        @include above($lg) {
            margin: 0 auto;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__name {
        font-size: 18px;
        line-height: 1.33333333;
        margin-bottom: 6px;

        @include above($lg) {
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 9px;
        }
    }

    &__description {
        color: rgba($color-gentian-blue, 0.6);
        font-size: 12px;
        font-weight: $font--medium;
        line-height: 1.33333333;
        margin: 0;

        @include above($lg) {
            font-size: 16px;
            line-height: $lh--base;
        }
    }
}
