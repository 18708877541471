.universal-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: none;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;

    &.is-active {
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    &--portfolio.is-active {
        justify-content: initial;
        align-items: center;

        @include above($md) {
            display: block;
            justify-content: initial;
            align-items: initial;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        background: rgba($color-violet-blue, 0.4);
    }

    &__content {
        position: relative;
        margin-top: 50px;
        margin-bottom: 20px;
        z-index: 1;

        @include above($lg) {
            margin-bottom: 50px;
        }
    }

    &--portfolio.is-active &__content {
        @include below($md) {
            margin-top: 0;
            margin-bottom: 0;
        }

        width: 100%;
        max-width: $extra-md;
        margin-left: auto;
        margin-right: auto;
    }

    &__close-btn {
        position: absolute;
        z-index: 2;
        top: 25px;
        right: 25px;
        cursor: pointer;
        background-color: transparent;
        transition: fill 0.2s ease-out;
        fill: $color-cinnabar;

        :root:not(.mobile) &:hover {
            fill: $color-fire-truck-color;
        }
    }

    &__inner {
        width: 100%;
        background-color: $color-white;
        padding: 22px 26px 23px 16px;
        border-radius: 20px;

        @include above($lg) {
            width: 880px;
            padding: 25px 26px 40px 26px;
        }
    }

    &--portfolio &__inner {
        padding: 22px 16px;

        @include above($extra-md) {
            padding: 24px;
            width: auto;
        }
    }
}
