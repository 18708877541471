.service-detailed {
    font-size: 14px;
    line-height: 1.71;
    color: $color-gentian-blue;

    @include below($lg) {
        font-size: 16px;
        line-height: 1.5;
    }

    &__section {
        margin-bottom: 36px;
    }

    &__title {
        max-width: 90%;
        font-size: 20px;
        line-height: 1.2;

        @include above($lg) {
            font-size: 22px;
        }
    }

    &__intro {
        margin-top: 6px;
    }

    &__subsection {
        margin-top: 22px;

        @include above($lg) {
            margin-top: 32px;
        }
    }

    &__subtitle {
        display: block;
        margin-bottom: 10px;
        font-weight: $font--semi-bold;
        font-size: 16px;
        line-height: 1.25;

        @include above($lg) {
            font-size: 18px;
            line-height: 1.22;
        }
    }

    p + p {
        margin-top: 5px;
    }
}
