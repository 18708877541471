.solid-btn {
    width: 100%;
    padding: 12px 30px;
    font-size: 20px;
    font-weight: $font--normal;
    line-height: 1.6;
    color: $color-white;
    transition-property: background;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    white-space: nowrap;
    border-radius: 80px;
    outline: none;

    @include above($lg) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &--cinnabar {
        background-color: $color-cinnabar;

        &:hover,
        &:active {
            background-color: $color-fire-truck-color;
        }
    }
}
