.portfolio-card {
    @include below($lg) {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 288px;
    }

    @include above($lg) {
        position: relative;
        padding-left: 128px;
        padding-top: 32px;
        min-height: 344px;
    }

    &__img-wrap {
        overflow: hidden;
        background-color: $color-selago;
        box-shadow: 0 2px 48px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        position: relative;
        flex: none;
        margin-bottom: -15px;
        width: 272px;
        height: 232px;

        @include above($lg) {
            position: absolute;
            left: 0;
            top: 0;
            margin-bottom: 0;
            width: 720px;
            height: 312px;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        border-radius: 20px;
        box-shadow: 0 2px 48px rgba(0, 0, 0, 0.15);
        background-color: $color-white;
        padding-top: 38px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        width: 256px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include below($lg) {
            flex-grow: 1;
            width: 256px;
        }

        @include above($lg) {
            padding-top: 47px;
            padding-left: 663px;
            padding-right: 72px;
            padding-bottom: 36px;
            min-height: 312px;
            width: 100%;
        }
    }

    &__name {
        margin-bottom: 6px;
    }

    &__date {
        display: block;
        color: rgba($color-gentian-blue, 0.6);
        font-size: 12px;
        font-weight: $font--medium;
        line-height: 1;
        margin-bottom: 36px;

        @include above($lg) {
            font-size: 16px;
        }
    }

    &__more-btn {
        margin-top: auto;
    }
}
