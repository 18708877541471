.footer {
    padding-top: 33px;
    padding-bottom: 31px;
    background-color: $color-special-white;

    @include below($lg) {
        font-size: 12px;
        line-height: 1.33;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include above($lg) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__development {
        color: $color-habrahabr;
        margin-top: 16px;

        @include above($lg) {
            padding-right: 25px;
            margin-top: 0;
        }
    }

    &__development-link {
        display: inline-block;
        margin-left: 4px;
        color: $color-gentian-blue;

        &:focus-visible {
            outline: thin dotted black;
        }
    }
}
