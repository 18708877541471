.about {
    padding-top: 30px;
    padding-bottom: 34px;

    @include above($lg) {
        padding-top: 10px;
    }

    &__introduction {
        @include above($lg) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &__img-wrap {
        @include below($lg) {
            display: none;
        }

        @include above($lg) {
            width: 568px;
        }
    }

    &__information {
        @include above($lg) {
            width: 568px;
            padding-top: 103px;
            margin-left: 15px;
        }
    }

    &__description {
        margin-top: 10px;
        font-weight: $font--medium;
        font-size: 14px;
        line-height: 1.71;

        @include above($lg) {
            margin-top: 18px;
            font-size: 20px;
            line-height: 1.6;
        }
    }

    &__description-text + &__description-text {
        margin-top: 18px;

        @include above($lg) {
            margin-top: 31px;
        }
    }

    &__features {
        margin-top: 18px;
    }

    &__features-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-left: -16px;

        @include above($lg) {
            margin-top: -16px;
        }
    }

    &__features-item {
        margin-top: 18px;
        width: 288px;
        height: 248px;
        margin-left: 16px;

        @include above($lg) {
            width: 272px;
            height: 295px;
            margin-top: 16px;
        }

        &:nth-child(3) {
            position: relative;
            z-index: 1;

            &::after {
                content: '';
                position: absolute;
                bottom: -37px;
                right: 0;
                width: 72px;
                height: 60px;

                @include dots-gradient(rgba($color-blue-degree, 0.6));

                @include above($lg) {
                    content: none;
                }
            }
        }
    }
}
