// Font families
// @type List
$font-stack--base: 'Montserrat', sans-serif !default;

// Font-sizes
$fs16: 16px;
//line-height
$lh24: 24px;

// @type Length
$font-size--page: $fs16 !default;
$font-size--text: $font-size--page !default;

// Font weights
// @type Length
$font--ultra-light: 100 !default;
$font--light: 200 !default; // or thin
$font--book: 300 !default;
$font--demi: 350 !default;
$font--normal: 400 !default; // or regular
$font--medium: 500 !default;
$font--semi-bold: 600 !default; // or demi-bold
$font--bold: 700 !default;
$font--heavy: 800 !default; // or black/extra-bold
$font--fat: 900 !default; // or extra-black/poster/ultra-black

// Colors
// @type Color
$color-white: #fff !default;
$color-gentian-blue: #0f2437;
$color-violet-blue: #314762;
$color-cinnabar: #ff4116;
$color-fire-truck-color: #d71e1e;
$color-gainsborough: #e0e0e0;
$color-special-white: #fbfbfb;
$color-habrahabr: #8ba1bc;
$color-blue-degree: #004efe;
$color-selago: #f6f9fe;
// text colors
$color--text: $color-gentian-blue;

// background
$color-bg--page: $color-white;

//borders
$border-color: $color--text;

// link
$color-link: $color-white !default;

// Default line-height
// @type Length
$lh--base: $lh24 / $fs16; // 24/16

// Base grid width
// @type Length
$width--min: 320px !default;

// Transitions
// @type Property Values
$transition--default: all 0.2s ease-out;

$md: 768px;
$extra-md: 880px;
$lg: 1280px;
