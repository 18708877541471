.mobile-menu {
    font-family: $font-stack--base;
    font-size: $font-size--page;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 65px;
    height: calc(100% - 65px);
    overflow-y: auto;
    background-color: $color-white;
    width: 0;
    transform: translateX(-300%);
    will-change: transform;
    transition-property: transform;
    transition-duration: 350ms;
    transition-timing-function: ease-in-out;

    &.is-active {
        width: 100%;
        transform: translateX(0);
    }

    @include above($lg) {
        display: none;
    }

    &__inner {
        padding-top: 39px;
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &__item {
        text-align: center;
    }

    &__link {
        display: block;
        padding-top: 20px;
        padding-bottom: 19px;
        font-weight: $font--semi-bold;
        font-size: 16px;
        line-height: inherit;
    }

    &__footer {
        padding-top: 89px;
        padding-bottom: 50px;
    }
}
