.portfolio-thumb-slider {
    position: relative;
    margin-right: -16px;

    @include above($extra-md) {
        flex: none;
        width: 128px;
        margin-right: 0;
    }

    &__list:not(.slick-initialized) {
        list-style: none;
        margin: 0;
        margin-left: -4px;
        padding: 0;
        display: flex;
        overflow: auto;

        @include above($extra-md) {
            margin-left: 0;
            margin-top: -8px;
        }
    }

    &__slide {
        margin-left: 4px;
        margin-right: 4px;

        @include above($extra-md) {
            margin-left: 0;
            margin-right: 0;
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }

    &__img-wrap {
        width: 80px;
        height: 56px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        @include above($extra-md) {
            width: 128px;
            height: 80px;
        }

        .portfolio-thumb-slider__slide.slick-current & {
            border: 2px solid $color-cinnabar;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(0deg, rgba(49, 71, 98, 0.5), rgba(49, 71, 98, 0.5));
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__btn {
        border-radius: 10px;
        width: 128px;
        height: 24px;
        border: 2px solid $color-gainsborough;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-cinnabar;
        transition: 0.2s border-color ease-out;

        &:hover,
        &:focus {
            border-color: $color-cinnabar;
        }

        &.slick-disabled,
        &.slick-disabled:hover,
        &.slick-disabled:focus {
            border-color: $color-gainsborough;
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }

        @include below($extra-md) {
            display: none;
        }
    }

    &__btn-icon {
        fill: currentColor;
    }
}
