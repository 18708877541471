.portfolio {
    margin-bottom: 67px;

    @include above($lg) {
        margin-bottom: 106px;
    }

    &__title {
        margin-bottom: 10px;

        @include above($lg) {
            margin-bottom: 27px;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        @include below($lg) {
            display: flex;
            flex-wrap: wrap;
        }

        @include above($lg) {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -18px;
                left: 216px;
                width: 72px;
                height: 95px;
                z-index: -1;

                @include dots-gradient(rgba($color-blue-degree, 0.6));
            }
        }
    }

    &__list-item {
        @include below($lg) {
            display: flex;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        & + & {
            @include above($lg) {
                margin-top: 32px;
            }
        }
    }

    &__load-more-btn {
        margin-top: 16px;

        @include above($lg) {
            margin-top: 48px;
        }
    }
}
