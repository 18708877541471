.features-card {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/about/features-bg-sm.png');
    background-repeat: no-repeat;

    @include above($lg) {
        background-image: url('../../assets/images/about/features-bg-lg.png');
    }

    &__container {
        padding: 40px 18px 52px 18px;

        @include above($lg) {
            padding: 40px 26px 46px 26px;
        }
    }

    &__img-wrap {
        margin-left: 6px;
        width: 56px;
        height: 57px;
    }

    &__img {
        display: block;
        width: 100%;
    }

    &__title {
        margin-top: 20px;

        @include above($lg) {
            margin-top: 28px;
        }
    }

    &__description {
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.71;
        opacity: 0.6;

        @include above($lg) {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}
